// Тут додаємо імпорт готового модулю за зразком:
import * as mobilMenu from './mobile-menu';
import * as switcher from './switcher';
// import * as moduleName from './js/module-name';
// import * as Catalog from './render-catalog'
import * as Hero from './hero';
import * as WeeklyTrends from './render_weeklytrends';
import * as upcomingMounth from '../js/upcoming-this-mounth';
import * as modal from './modal-info';
import * as scrollUpBtn from './scroll-up-btn';
import * as openTeamModal from './our-team-modal-open';
